// Padding
.pad {
    padding: $padding-size;

    &-0 {
        padding: 0;
    }

    &-vert {
        padding-top: $padding-size;
        padding-bottom: $padding-size;
    }

    &-horiz {
        padding-left: $padding-size;
        padding-right: $padding-size;
    }

    &-top {
        padding-top: $padding-size;
    }

    &-bottom {
        padding-bottom: $padding-size;
    }

    &-left {
        padding-left: $padding-size;
    }

    &-right {
        padding-right: $padding-size;
    }

    // padding x2
    &-2x {
        padding: ($padding-size * 2);

        &-vert {
            padding-top: ($padding-size * 2);
            padding-bottom: ($padding-size * 2);
        }

        &-horiz {
            padding-left: ($padding-size * 2);
            padding-right: ($padding-size * 2);
        }

        &-top {
            padding-top: ($padding-size * 2);
        }

        &-bottom {
            padding-bottom: ($padding-size * 2);
        }

        &-left {
            padding-left: ($padding-size * 2);
        }

        &-right {
            padding-right: ($padding-size * 2);
        }
    }

    // padding x3
    &-3x {
        padding: ($padding-size * 3);

        &-vert {
            padding-top: ($padding-size * 3);
            padding-bottom: ($padding-size * 3);
        }

        &-horiz {
            padding-left: ($padding-size * 3);
            padding-right: ($padding-size * 3);
        }

        &-top {
            padding-top: ($padding-size * 3);
        }

        &-bottom {
            padding-bottom: ($padding-size * 3);
        }

        &-left {
            padding-left: ($padding-size * 3);
        }

        &-right {
            padding-right: ($padding-size * 3);
        }
    }

    @include MQ(M) {
        padding: $padding-size--medium;

        &-vert {
            padding-top: $padding-size--medium;
            padding-bottom: $padding-size--medium;
        }

        &-horiz {
            padding-left: $padding-size--medium;
            padding-right: $padding-size--medium;
        }

        &-top {
            padding-top: $padding-size--medium;
        }

        &-bottom {
            padding-bottom: $padding-size--medium;
        }

        &-left {
            padding-left: $padding-size--medium;
        }

        &-right {
            padding-right: $padding-size--medium;
        }

        // padding x2
        &-2x {
            padding: ($padding-size--medium * 2);

            &-vert {
                padding-top: ($padding-size--medium * 2);
                padding-bottom: ($padding-size--medium * 2);
            }

            &-horiz {
                padding-left: ($padding-size--medium * 2);
                padding-right: ($padding-size--medium * 2);
            }

            &-top {
                padding-top: ($padding-size--medium * 2);
            }

            &-bottom {
                padding-bottom: ($padding-size--medium * 2);
            }

            &-left {
                padding-left: ($padding-size--medium * 2);
            }

            &-right {
                padding-right: ($padding-size--medium * 2);
            }
        }

        // padding x3
        &-3x {
            padding: ($padding-size--medium * 3);

            &-vert {
                padding-top: ($padding-size--medium * 3);
                padding-bottom: ($padding-size--medium * 3);
            }

            &-horiz {
                padding-left: ($padding-size--medium * 3);
                padding-right: ($padding-size--medium * 3);
            }

            &-top {
                padding-top: ($padding-size--medium * 3);
            }

            &-bottom {
                padding-bottom: ($padding-size--medium * 3);
            }

            &-left {
                padding-left: ($padding-size--medium * 3);
            }

            &-right {
                padding-right: ($padding-size--medium * 3);
            }
        }
    }

    @include MQ(L) {
        padding: $padding-size--large;

        &-vert {
            padding-top: $padding-size--large;
            padding-bottom: $padding-size--large;
        }

        &-horiz {
            padding-left: $padding-size--large;
            padding-right: $padding-size--large;
        }

        &-top {
            padding-top: $padding-size--large;
        }

        &-bottom {
            padding-bottom: $padding-size--large;
        }

        &-left {
            padding-left: $padding-size--large;
        }

        &-right {
            padding-right: $padding-size--large;
        }

        // padding x2
        &-2x {
            padding: ($padding-size--large * 2);

            &-vert {
                padding-top: ($padding-size--large * 2);
                padding-bottom: ($padding-size--large * 2);
            }

            &-horiz {
                padding-left: ($padding-size--large * 2);
                padding-right: ($padding-size--large * 2);
            }

            &-top {
                padding-top: ($padding-size--large * 2);
            }

            &-bottom {
                padding-bottom: ($padding-size--large * 2);
            }

            &-left {
                padding-left: ($padding-size--large * 2);
            }

            &-right {
                padding-right: ($padding-size--large * 2);
            }
        }

        // padding x3
        &-3x {
            padding: ($padding-size--large * 3);

            &-vert {
                padding-top: ($padding-size--large * 3);
                padding-bottom: ($padding-size--large * 3);
            }

            &-horiz {
                padding-left: ($padding-size--large * 3);
                padding-right: ($padding-size--large * 3);
            }

            &-top {
                padding-top: ($padding-size--large * 3);
            }

            &-bottom {
                padding-bottom: ($padding-size--large * 3);
            }

            &-left {
                padding-left: ($padding-size--large * 3);
            }

            &-right {
                padding-right: ($padding-size--large * 3);
            }
        }
    }
}