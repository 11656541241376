.row {
    max-width: $grid-row-width;
}

// Collapse classes

.small-collapse {

    >.column,
    >.columns {
        padding-right: 0;
        padding-left: 0;
    }
}

.small-uncollapse {

    >.column,
    >.columns {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}

@include MQ(M) {
    .medium-collapse {

        >.column,
        >.columns {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .medium-uncollapse {

        >.column,
        >.columns {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }
    }
}

@include MQ(L) {
    .large-collapse {

        >.column,
        >.columns {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .large-uncollapse {

        >.column,
        >.columns {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }
    }
}

@include MQ(XL) {
    .xl-collapse {

        >.column,
        >.columns {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .xl-uncollapse {

        >.column,
        >.columns {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }
    }
}

@include MQ(XXL) {
    .xxl-collapse {

        >.column,
        >.columns {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .xxl-uncollapse {

        >.column,
        >.columns {
            padding-right: 0.9375rem;
            padding-left: 0.9375rem;
        }
    }
}

// Expand classes

@include MQ(M) {
    .medium-expand {
        flex: 1 1 0px;
    }
}

@include MQ(L) {
    .large-expand {
        flex: 1 1 0px;
        max-width: none;
    }
}

@include MQ(XL) {
    .xl-expand {
        flex: 1 1 0px;
    }
}

@include MQ(XXL) {
    .xxl-expand {
        flex: 1 1 0px;
    }
}

// Unstack classes

.row.small-unstack>.column,
.row.small-unstack>.columns {
    flex: 0 0 100%;
    flex: 1 1 0px;
}

@include MQ(M) {

    .row.medium-unstack>.column,
    .row.medium-unstack>.columns {
        flex: 0 0 100%;
        flex: 1 1 0px;
    }
}

@include MQ(L) {

    .row.large-unstack>.column,
    .row.large-unstack>.columns {
        flex: 0 0 100%;
        flex: 1 1 0px;
    }
}

@include MQ(XL) {

    .row.xl-unstack>.column,
    .row.xl-unstack>.columns {
        flex: 0 0 100%;
        flex: 1 1 0px;
    }
}

@include MQ(XXL) {

    .row.xxl-unstack>.column,
    .row.xxl-unstack>.columns {
        flex: 0 0 100%;
        flex: 1 1 0px;
    }
}

// Shrink classes

.shrink {
    flex: 0 0 auto;
    max-width: 100%;
}

@include MQ(M) {
    .medium-shrink {
        flex: 0 0 auto;
        max-width: 100%;
    }
}

@include MQ(L) {
    .large-shrink {
        flex: 0 0 auto;
        max-width: 100%;
    }
}

@include MQ(XL) {
    .xl-shrink {
        flex: 0 0 auto;
        max-width: 100%;
    }
}

@include MQ(XXL) {
    .xxl-shrink {
        flex: 0 0 auto;
        max-width: 100%;
    }
}

// Alignment classes

.align-right {
    justify-content: flex-end;
}

.align-left {
    justify-content: flex-start;
}

.align-center {
    justify-content: center;
}

.align-justify {
    justify-content: space-between;
}

.align-spaced {
    justify-content: space-around;
}

.align-top {
    align-items: flex-start;
}

.align-self-top {
    align-self: flex-start;
}

.align-bottom {
    align-items: flex-end;
}

.align-self-bottom {
    align-self: flex-end;
}

.align-middle {
    align-items: center;
}

.align-self-middle {
    align-self: center;
}

.align-stretch {
    align-items: stretch;
}

.align-self-stretch {
    align-self: stretch;
}

.align-center-middle {
    justify-content: center;
    align-items: center;
    align-content: center;
}

@include MQ(M) {
    .medium-align-right {
        justify-content: flex-end;
    }

    .medium-align-left {
        justify-content: flex-start;
    }

    .medium-align-center {
        justify-content: center;
    }

    .medium-align-justify {
        justify-content: space-between;
    }

    .medium-align-spaced {
        justify-content: space-around;
    }

    .medium-align-top {
        align-items: flex-start;
    }

    .medium-align-self-top {
        align-self: flex-start;
    }

    .medium-align-bottom {
        align-items: flex-end;
    }

    .medium-align-self-bottom {
        align-self: flex-end;
    }

    .medium-align-middle {
        align-items: center;
    }

    .medium-align-self-middle {
        align-self: center;
    }

    .medium-align-stretch {
        align-items: stretch;
    }

    .medium-align-self-stretch {
        align-self: stretch;
    }

    .medium-align-center-middle {
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@include MQ(L) {
    .large-align-right {
        justify-content: flex-end;
    }

    .large-align-left {
        justify-content: flex-start;
    }

    .large-align-center {
        justify-content: center;
    }

    .large-align-justify {
        justify-content: space-between;
    }

    .large-align-spaced {
        justify-content: space-around;
    }

    .large-align-top {
        align-items: flex-start;
    }

    .large-align-self-top {
        align-self: flex-start;
    }

    .large-align-bottom {
        align-items: flex-end;
    }

    .large-align-self-bottom {
        align-self: flex-end;
    }

    .large-align-middle {
        align-items: center;
    }

    .large-align-self-middle {
        align-self: center;
    }

    .large-align-stretch {
        align-items: stretch;
    }

    .large-align-self-stretch {
        align-self: stretch;
    }

    .large-align-center-middle {
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@include MQ(XL) {
    .xl-align-right {
        justify-content: flex-end;
    }

    .xl-align-left {
        justify-content: flex-start;
    }

    .xl-align-center {
        justify-content: center;
    }

    .xl-align-justify {
        justify-content: space-between;
    }

    .xl-align-spaced {
        justify-content: space-around;
    }

    .xl-align-top {
        align-items: flex-start;
    }

    .xl-align-self-top {
        align-self: flex-start;
    }

    .xl-align-bottom {
        align-items: flex-end;
    }

    .xl-align-self-bottom {
        align-self: flex-end;
    }

    .xl-align-middle {
        align-items: center;
    }

    .xl-align-self-middle {
        align-self: center;
    }

    .xl-align-stretch {
        align-items: stretch;
    }

    .xl-align-self-stretch {
        align-self: stretch;
    }

    .xl-align-center-middle {
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@include MQ(XXL) {
    .xxl-align-right {
        justify-content: flex-end;
    }

    .xxl-align-left {
        justify-content: flex-start;
    }

    .xxl-align-center {
        justify-content: center;
    }

    .xxl-align-justify {
        justify-content: space-between;
    }

    .xxl-align-spaced {
        justify-content: space-around;
    }

    .xxl-align-top {
        align-items: flex-start;
    }

    .xxl-align-self-top {
        align-self: flex-start;
    }

    .xxl-align-bottom {
        align-items: flex-end;
    }

    .xxl-align-self-bottom {
        align-self: flex-end;
    }

    .xxl-align-middle {
        align-items: center;
    }

    .xxl-align-self-middle {
        align-self: center;
    }

    .xxl-align-stretch {
        align-items: stretch;
    }

    .xxl-align-self-stretch {
        align-self: stretch;
    }

    .xxl-align-center-middle {
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

// Order classes

.small-order-1 {
    order: 1;
}

.small-order-2 {
    order: 2;
}

.small-order-3 {
    order: 3;
}

.small-order-4 {
    order: 4;
}

.small-order-5 {
    order: 5;
}

.small-order-6 {
    order: 6;
}

@include MQ(M) {
    .medium-order-1 {
        order: 1;
    }

    .medium-order-2 {
        order: 2;
    }

    .medium-order-3 {
        order: 3;
    }

    .medium-order-4 {
        order: 4;
    }

    .medium-order-5 {
        order: 5;
    }

    .medium-order-6 {
        order: 6;
    }
}

@include MQ(L) {
    .large-order-1 {
        order: 1;
    }

    .large-order-2 {
        order: 2;
    }

    .large-order-3 {
        order: 3;
    }

    .large-order-4 {
        order: 4;
    }

    .large-order-5 {
        order: 5;
    }

    .large-order-6 {
        order: 6;
    }
}

@include MQ(XL) {
    .xl-order-1 {
        order: 1;
    }

    .xl-order-2 {
        order: 2;
    }

    .xl-order-3 {
        order: 3;
    }

    .xl-order-4 {
        order: 4;
    }

    .xl-order-5 {
        order: 5;
    }

    .xl-order-6 {
        order: 6;
    }
}

@include MQ(XXL) {
    .xxl-order-1 {
        order: 1;
    }

    .xxl-order-2 {
        order: 2;
    }

    .xxl-order-3 {
        order: 3;
    }

    .xxl-order-4 {
        order: 4;
    }

    .xxl-order-5 {
        order: 5;
    }

    .xxl-order-6 {
        order: 6;
    }
}