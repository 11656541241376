// Colors
.color {
    &-primary {
        color: $primary-color;
    }

    &-secondary {
        color: $secondary-color;
    }

    &-third {
        color: $third-color;
    }

    &-white {
        color: $white;
    }

    &-black {
        color: $black;
    }
}

.background {
    &-primary {
        background: $primary-color;
    }

    &-secondary {
        background: $secondary-color;
    }

    &-third {
        background: $third-color;
    }

    &-white {
        color: $white;
    }

    &-black {
        color: $black;
    }
}