// Margin
.marg {
    margin: $margin-size;

    &-0 {
        margin: 0;
    }

    &-vert {
        margin-top: $margin-size;
        margin-bottom: $margin-size;
    }

    &-horiz {
        margin-left: $margin-size;
        margin-right: $margin-size;
    }

    &-top {
        margin-top: $margin-size;
    }

    &-bottom {
        margin-bottom: $margin-size;
    }

    &-left {
        margin-left: $margin-size;
    }

    &-right {
        margin-right: $margin-size;
    }

    // Margin x0.5
    &-half {
        margin: ($margin-size * 0.5);

        &-vert {
            margin-top: ($margin-size * 0.5);
            margin-bottom: ($margin-size * 0.5);
        }

        &-horiz {
            margin-left: ($margin-size * 0.5);
            margin-right: ($margin-size * 0.5);
        }

        &-top {
            margin-top: ($margin-size * 0.5);
        }

        &-bottom {
            margin-bottom: ($margin-size * 0.5);
        }

        &-left {
            margin-left: ($margin-size * 0.5);
        }

        &-right {
            margin-right: ($margin-size * 0.5);
        }
    }

    // Margin x2
    &-2x {
        margin: ($margin-size * 2);

        &-vert {
            margin-top: ($margin-size * 2);
            margin-bottom: ($margin-size * 2);
        }

        &-horiz {
            margin-left: ($margin-size * 2);
            margin-right: ($margin-size * 2);
        }

        &-top {
            margin-top: ($margin-size * 2);
        }

        &-bottom {
            margin-bottom: ($margin-size * 2);
        }

        &-left {
            margin-left: ($margin-size * 2);
        }

        &-right {
            margin-right: ($margin-size * 2);
        }
    }

    // Margin x3
    &-3x {
        margin: ($margin-size * 3);

        &-vert {
            margin-top: ($margin-size * 3);
            margin-bottom: ($margin-size * 3);
        }

        &-horiz {
            margin-left: ($margin-size * 3);
            margin-right: ($margin-size * 3);
        }

        &-top {
            margin-top: ($margin-size * 3);
        }

        &-bottom {
            margin-bottom: ($margin-size * 3);
        }

        &-left {
            margin-left: ($margin-size * 3);
        }

        &-right {
            margin-right: ($margin-size * 3);
        }
    }

    @include MQ(M) {
        margin: $margin-size--medium;

        &-vert {
            margin-top: $margin-size--medium;
            margin-bottom: $margin-size--medium;
        }

        &-horiz {
            margin-left: $margin-size--medium;
            margin-right: $margin-size--medium;
        }

        &-top {
            margin-top: $margin-size--medium;
        }

        &-bottom {
            margin-bottom: $margin-size--medium;
        }

        &-left {
            margin-left: $margin-size--medium;
        }

        &-right {
            margin-right: $margin-size--medium;
        }

        // Margin x0.5
        &-half {
            margin: ($margin-size--medium * 0.5);

            &-vert {
                margin-top: ($margin-size--medium * 0.5);
                margin-bottom: ($margin-size--medium * 0.5);
            }

            &-horiz {
                margin-left: ($margin-size--medium * 0.5);
                margin-right: ($margin-size--medium * 0.5);
            }

            &-top {
                margin-top: ($margin-size--medium * 0.5);
            }

            &-bottom {
                margin-bottom: ($margin-size--medium * 0.5);
            }

            &-left {
                margin-left: ($margin-size--medium * 0.5);
            }

            &-right {
                margin-right: ($margin-size--medium * 0.5);
            }
        }

        // Margin x2
        &-2x {
            margin: ($margin-size--medium * 2);

            &-vert {
                margin-top: ($margin-size--medium * 2);
                margin-bottom: ($margin-size--medium * 2);
            }

            &-horiz {
                margin-left: ($margin-size--medium * 2);
                margin-right: ($margin-size--medium * 2);
            }

            &-top {
                margin-top: ($margin-size--medium * 2);
            }

            &-bottom {
                margin-bottom: ($margin-size--medium * 2);
            }

            &-left {
                margin-left: ($margin-size--medium * 2);
            }

            &-right {
                margin-right: ($margin-size--medium * 2);
            }
        }

        // Margin x3
        &-3x {
            margin: ($margin-size--medium * 3);

            &-vert {
                margin-top: ($margin-size--medium * 3);
                margin-bottom: ($margin-size--medium * 3);
            }

            &-horiz {
                margin-left: ($margin-size--medium * 3);
                margin-right: ($margin-size--medium * 3);
            }

            &-top {
                margin-top: ($margin-size--medium * 3);
            }

            &-bottom {
                margin-bottom: ($margin-size--medium * 3);
            }

            &-left {
                margin-left: ($margin-size--medium * 3);
            }

            &-right {
                margin-right: ($margin-size--medium * 3);
            }
        }
    }

    @include MQ(L) {
        margin: $margin-size--large;

        &-vert {
            margin-top: $margin-size--large;
            margin-bottom: $margin-size--large;
        }

        &-horiz {
            margin-left: $margin-size--large;
            margin-right: $margin-size--large;
        }

        &-top {
            margin-top: $margin-size--large;
        }

        &-bottom {
            margin-bottom: $margin-size--large;
        }

        &-left {
            margin-left: $margin-size--large;
        }

        &-right {
            margin-right: $margin-size--large;
        }

        // Margin x0.5
        &-half {
            margin: ($margin-size--large * 0.5);

            &-vert {
                margin-top: ($margin-size--large * 0.5);
                margin-bottom: ($margin-size--large * 0.5);
            }

            &-horiz {
                margin-left: ($margin-size--large * 0.5);
                margin-right: ($margin-size--large * 0.5);
            }

            &-top {
                margin-top: ($margin-size--large * 0.5);
            }

            &-bottom {
                margin-bottom: ($margin-size--large * 0.5);
            }

            &-left {
                margin-left: ($margin-size--large * 0.5);
            }

            &-right {
                margin-right: ($margin-size--large * 0.5);
            }
        }

        // Margin x2
        &-2x {
            margin: ($margin-size--large * 2);

            &-vert {
                margin-top: ($margin-size--large * 2);
                margin-bottom: ($margin-size--large * 2);
            }

            &-horiz {
                margin-left: ($margin-size--large * 2);
                margin-right: ($margin-size--large * 2);
            }

            &-top {
                margin-top: ($margin-size--large * 2);
            }

            &-bottom {
                margin-bottom: ($margin-size--large * 2);
            }

            &-left {
                margin-left: ($margin-size--large * 2);
            }

            &-right {
                margin-right: ($margin-size--large * 2);
            }
        }

        // Margin x3
        &-3x {
            margin: ($margin-size--large * 3);

            &-vert {
                margin-top: ($margin-size--large * 3);
                margin-bottom: ($margin-size--large * 3);
            }

            &-horiz {
                margin-left: ($margin-size--large * 3);
                margin-right: ($margin-size--large * 3);
            }

            &-top {
                margin-top: ($margin-size--large * 3);
            }

            &-bottom {
                margin-bottom: ($margin-size--large * 3);
            }

            &-left {
                margin-left: ($margin-size--large * 3);
            }

            &-right {
                margin-right: ($margin-size--large * 3);
            }
        }
    }
}