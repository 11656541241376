.footer {
    background: $primary-color;
    padding: 2rem 0 4rem 0;

    &__copy {
        color: $white;
        margin: 0 auto;
        font-size: rem-calc(14);
        font-family: $secondary-font;
        font-weight: 500;
    }

    &__number {
        color: $white;
        margin: 0;
        font-size: 1rem;
        font-family: $secondary-font;
        font-weight: 500;
        margin-top: 1.5rem;
        margin-bottom: 0.25rem;

        @include MQ(M) {
            margin-top: 0;
        }
    }

    &__text {
        display: block;
        color: rgba($white, 0.6);
        margin: 0;
    }

    &__phone,
    &__link {
        display: inline-block;
        color: $white;
        color: rgba($white, 0.6);
        margin-bottom: 0.5rem;

        &:hover {
            color: $white;
        }
    }

    &__logo {
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }
    }
}