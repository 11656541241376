.banner {
    position: relative;
    z-index: 10;
    overflow: hidden;
    height: 26rem;

    @include MQ(M) {
        height: 34rem;
    }

    @include MQ(L) {
        height: auto;

        &:before {
            content: "";
            display: block;
            position: relative;
            height: 0;
            width: 100%;
            padding-top: 33.33%;
        }
    }

    &__image {
        position: absolute;
        max-width: none;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        width: auto;
        top: -999px;
        bottom: -999px;
        left: -999px;
        right: -999px;
        margin: auto;

        @supports(object-fit: cover) {
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: 0;
            object-position: left;
        }

        @include MQ(L) {
            @supports(object-fit: cover) {
                object-position: center;
            }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 2rem;
    }

    &__title {
        margin: 0;
        line-height: 1.125;
        color: $white;
        font-weight: 500;
        font-size: rem-calc(40);
        text-shadow: 0px 2px 40px rgba(0, 0, 0, 0.66);

        @include MQ(L) {
            line-height: 1;
            font-size: rem-calc(50);
        }
    }
}