//! If you edit these make sure to make the same change in the settings/variables file.
// breakpoints
$small: 0;
$medium: rem-calc(640);
$large: rem-calc(1024);
$xlarge: rem-calc(1200);
$xxlarge: rem-calc(1440);
$xxxlarge: rem-calc(1600);

// media queries

@mixin MQ($canvas) {
    @if $canvas==S {
        @media only screen and (min-width: $small) {
            @content;
        }
    }

    @else if $canvas==M {
        @media only screen and (min-width: $medium) {
            @content;
        }
    }

    @else if $canvas==L {
        @media only screen and (min-width: $large) {
            @content;
        }
    }

    @else if $canvas==XL {
        @media only screen and (min-width: $xlarge) {
            @content;
        }
    }

    @else if $canvas==XXL {
        @media only screen and (min-width: $xxlarge) {
            @content;
        }
    }

    @else if $canvas==XXXL {
        @media only screen and (min-width: $xxxlarge) {
            @content;
        }
    }
}

// Placeholders
@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

@function get-line-height($font-size, $line-height) {
    @return $line-height / $font-size;
}