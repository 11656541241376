//Text

.font {
    &-primary {
        font-family: $primary-font;
    }

    &-secondary {
        font-family: $secondary-font;
    }
}

// Font Style
.fs {
    &-normal {
        font-style: normal;
    }

    &-italic {
        font-style: italic;
    }

    &-oblique {
        font-style: oblique;
    }
}

// Font Weight
.fw {
    &-100 {
        font-weight: 100;
    }

    &-200 {
        font-weight: 200;
    }

    &-300 {
        font-weight: 300;
    }

    &-400 {
        font-weight: 400;
    }

    &-500 {
        font-weight: 500;
    }

    &-600 {
        font-weight: 600;
    }

    &-700 {
        font-weight: 700;
    }

    &-800 {
        font-weight: 800;
    }

    &-900 {
        font-weight: 900;
    }

    &-bold {
        font-weight: bold;
    }

    &-bolder {
        font-weight: bolder;
    }

    &-lighter {
        font-weight: lighter;
    }

    &-normal {
        font-weight: normal;
    }

    &-unset {
        font-weight: unset;
    }
}

// Text Transform
.tt {
    &-uppercase {
        text-transform: uppercase;
    }

    &-titlecase,
    &-capitalize {
        text-transform: capitalize;
    }

    &-lowercase {
        text-transform: lowercase;
    }
}

// Text Decoration
.td {
    &-underline {
        text-decoration: underline;
    }

    &-overline {
        text-decoration: overline;
    }

    &-strike {
        text-decoration: line-through;
    }

    &-over-under {
        text-decoration: overline underline;
    }
}

// Text Align
.ta,
.text {
    &-left {
        text-align: left;
    }

    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }
}

// Responsive Text Alignment
.text-center-small {
    text-align: center;

    @include MQ(M) {
        text-align: left;
    }
}

.text-center-small-medium {
    text-align: center;

    @include MQ(L) {
        text-align: left;
    }
}

.text-center-medium {
    text-align: left;

    @include MQ(M) {
        text-align: center;
    }

    @include MQ(L) {
        text-align: left;
    }
}

.text-center-medium-large {
    text-align: left;

    @include MQ(M) {
        text-align: center;
    }
}

.text-center-large {
    text-align: left;

    @include MQ(L) {
        text-align: center;
    }
}

.text-center-small-right-medium {
    text-align: center;

    @include MQ(M) {
        text-align: right;
    }
}