.callout {
    position: relative;
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: #fff;
    border: 2px solid $primary-color;
    display: block;
    display: flex;
    margin: 0.5rem 0;

    @include MQ(M) {
        margin: 0;
        height: 100%;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: $primary-color;
            z-index: 1;
            transform: scaleX(0);
            transform-origin: center left;
        }

        @supports(filter: brightness(0)) {
            &:hover {
                &:before {
                    transform: scaleX(1);
                    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
                }

                .callout__text {
                    color: $white;
                    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
                }

                .callout__image {
                    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &__anchor {
        background: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        flex: 0 0 auto;
        z-index: 2;
    }

    &__icon {
        width: rem-calc(50);
        fill: $white;
    }

    &__body {
        padding: 0.75rem 1.5rem 0.75rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        z-index: 2;
    }

    &__text {
        font-size: rem-calc(28);
        color: $primary-color;
        font-family: $secondary-font;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 1;
        margin: 0;

        @include MQ(M) {
            font-size: rem-calc(24);
        }

        @include MQ(L) {
            font-size: rem-calc(28);
        }
    }

    &__image {
        filter: brightness(1) invert(0);
    }

    &--right {
        .callout__anchor {
            order: 2;
        }

        .callout__body {
            order: 1;
        }
    }

    &--secondary {
        border: 2px solid $secondary-color;

        .callout__anchor {
            background: $secondary-color;
        }

        @include MQ(M) {
            &:before {
                background: $secondary-color;
            }
        }
    }
}