.header {
    background: $white;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include MQ(M) {
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
    }

    &__logo {
        &__wrapper {
            background: $white;
            position: relative;
            padding: 1.5rem 1rem 0.5rem 1rem;

            @include MQ(M) {
                padding: 1.5rem 1rem 0.5rem 1rem;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -6%;
                    width: 112%;
                    height: 100%;
                    background: #ffffff;
                    z-index: -1;
                    transform: skew(-15deg);
                }
            }
        }
    }
}