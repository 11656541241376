.chip {
    display: inline-block;
    min-width: 0;
    padding: 0;
    border-radius: 0;
    font-size: 1rem;
    background: transparent;
    color: transparent;
    margin: 1rem 0;

    &__title {
        color: $primary-color;
        font-size: rem-calc(20);
        font-family: $secondary-font;
        font-weight: 500;
        margin: 0;
    }

    &__media {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 2px solid $secondary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;
        margin: 0 auto 0.75rem auto;
        padding: 0;
    }

    &__image {
        max-width: 80%;
        max-height: 80%;
        fill: $secondary-color;
    }
}