.content {

    &__wrapper {
        padding: 2rem 0;
        position: relative;
        z-index: 10;
        background: $white;

        @include MQ(M) {
            box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
            border-bottom: 15px solid #cacaca;
            margin-top: rem-calc(-38);
        }
    }
}