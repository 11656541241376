html {
    font-size: 16px;
    font-size: 1rem;
}

// Typography
body {
    font-family: $primary-font;
}

a {
    color: $primary-color;

    &:hover,
    &:focus,
    &:active {
        color: $primary-color-600;
        text-decoration: none;
    }
}

// Universal Heading Styles

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $secondary-font;
}

// Individual Heading Styles

h1 {}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: inherit;
}

.title {
    margin: 0;
    padding: 0;
}