.main {
    padding: 2rem 0;

    &--index {
        padding: 0;

        @include MQ(M) {
            background: linear-gradient(to bottom, white 0%, white 90%, #eeeeee 100%);
            padding-bottom: 2rem;
        }
    }
}